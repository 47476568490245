.ios-install-prompt-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.2s;
}

.ios-install-prompt-wrapper.hidden {
  opacity: 0;
  pointer-events: none;
}

.ios-install-prompt-modal {
  background: #fff;
  border-radius: 10px;
  color: #000;
  position: relative;
  text-align: center;
}

.ios-install-prompt-modal h1 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.ios-install-prompt-modal p {
  font-size: 17px;
}

.ios-install-prompt-modal .preview-app-icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 10px;
}

.ios-install-prompt-close {
  position: absolute;
  top: -20px;
  right: -10px;
  padding: 30px;
  font-size: 30px;
  border-radius: 40px;
  color: #bbb;
  cursor: pointer;
}

.ios-install-prompt-content {
  padding: 20px 20px 30px;
}

.ios-install-prompt-cta {
  padding: 14px 20px 20px;
  border-top: 1px solid #ddd;
}

.ios-install-prompt-cta img {
  vertical-align: -6px;
  margin: 0 6px;
}
